@import "~antd/dist/antd.css";

.App-header {
  background-color: white;
  min-height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: #505e6c;
  position: static;
  width: 100vh;
  height: 910px;
}

.ant-btn:hover,
.ant-btn:focus {
  background-color: #505e6c;
  border: 1px solid #505e6c;
  color: #ffffff;
}

.getstarted-button {
  border: none #505e6c 1px;
  top: 650px;
  left: 581px;
  width: 759px;
  height: 101px;
  background: #505e6c 0% 0% no-repeat padding-box;
  border-radius: 30px;
  opacity: 1;
  position: fixed;
  align-content: center;
  font: normal normal normal 22px/25px Quattrocento Sans;
  letter-spacing: 0.66px;
  color: #ffffff;
}

.getstarted-button:hover {
  background-color: #505e6c;
}

.get-started-company {
  color: #505e6c;
  position: fixed;
  top: 98px;
  left: 615px;
  width: 386px;
  height: 112px;
  text-align: left;
  font: normal normal bold 101px/112px Quattrocento Sans;
  letter-spacing: -2.42px;
}

.get-started-app {
  color: #505e6c;
  position: fixed;
  top: 196px;
  left: 615px;
  width: 298px;
  height: 112px;
  text-align: left;
  font: normal normal bold 101px/112px Quattrocento Sans;
  letter-spacing: -2.42px;
  color: #505e6c;
  opacity: 1;
}

.logo-redbird {
  position: fixed;
  top: 800px;
  left: 900px;
  width: 80px;
}

.logo-redbird-homepage {
  position: fixed;
  top: 739px;
  left: 931px;
  width: 80px;
}

.logo-whitebird {
  color: #ffffff;
  width: 48px;
  height: 35px;
  text-align: right;
  font: normal normal normal 20px/22px Quattrocento Sans;
  letter-spacing: 0.6px;
  opacity: 1;
}

.returns-nav {
  top: 50px;
  left: 100px;
}

.background-logo {
  position: fixed;
  display: flex;
  width: 100%;
  height: 100%;
  top: 0px;
  bottom: 0px;
}

.profile {
  position: fixed;
  top: 100px;
  left: 100px;
}

.header-link {
  background-color: #505e6c;
  border: none #505e6c 1px;
  text-align: left;
  font: normal normal normal 20px/22px Quattrocento Sans;
  letter-spacing: 0.6px;
  color: #ffffff;
  opacity: 1;
}

.header-link:hover {
  background-color: #505e6c;
  color: #ffffff;
}

nav {
  top: 0%;
  left: 0px;
  height: 106px;
  background: #505e6c 0% 0% no-repeat padding-box;
  opacity: 1;
  font: normal normal normal 20px/22px Quattrocento Sans;
  text-align: left;
  letter-spacing: 0.6px;
  color: #ffffff;
  opacity: 1;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  position: fixed;
  z-index: 10;
}

.return-confirmation-bar {
  position: fixed;
  top: 10%;
  width: 100%;
  height: 103px;
  background-color: #d9f0d0;
  text-align: right;
  font: italic normal bold 17px/18px Quattrocento Sans;
  letter-spacing: 0.34px;
  color: #31383e;
  opacity: 1;
  padding: 37px;
}

.warning-icon {
  top: 500px;
  height: 38px;
}

.warning-icon-noitem {
  height: 78px;
}

.noitem-message-title {
  font: normal normal normal 21px/23px Quattrocento Sans;
  letter-spacing: 0.63px;
  color: #31383e;
  opacity: 1;
  top: 35px;
  position: relative;
  height: 23px;
}

.noitem-message {
  width: 600px;
  left: -60px;
  top: 55px;
  position: relative;
  height: 18px;
  font: normal normal normal 17px/18px Quattrocento Sans;
  letter-spacing: 0.51px;
  color: #31383e;
  opacity: 1;
  text-align: left;
}

.homepage-label {
  top: 200px;
  left: 708px;
  width: 129px;
  height: 18px;
  text-align: left;
  font: normal normal normal 17px/18px Quattrocento Sans;
  letter-spacing: 0.85px;
  color: #31383e;
  opacity: 1;
  position: relative;
}

.homepage-input {
  top: 250px;
  left: 708px;
  width: 505px;
  height: 77px;
  background: #ffffff 0% 0% no-repeat padding-box;
  border: 1px solid #b7b7b7;
  border-radius: 15px;
  opacity: 1;
  text-align: left;
  font: italic normal normal 17px/18px Quattrocento Sans;
  letter-spacing: 0.17px;
  opacity: 1;
  position: fixed;
}

.homepage-start {
  top: 355px;
  left: 708px;
  width: 505px;
  height: 84px;
  border: 1px solid #31383e;
  border-radius: 20px;
  background-color: #ffffff;
  font: normal normal normal 17px/18px Quattrocento Sans;
  letter-spacing: 0.85px;
  color: #31383e;
  align-content: center;
  position: fixed;
}

.help-icon {
  top: 533px;
  left: 882px;
  width: 157px;
  height: 159px;
  border: 2px solid #31383e;
  border-radius: 30px;
  opacity: 1;
  position: fixed;
}
.returns-loading {
  top: 200px;
  left: 300px;
  text-align: left;
  font: italic normal normal 22px/25px Quattrocento Sans;
  color: #31383e;
  opacity: 1;
  position: relative;
}

.order-details {
  top: 10px;
  left: 250px;
  width: 200px;
  height: 25px;
  text-align: left;
  font: normal normal normal 22px/25px Quattrocento Sans;
  letter-spacing: 1.1px;
  color: #31383e;
  opacity: 1;
  position: relative;
}

.order-info {
  top: 110px;
  left: 250px;
  width: 1000px;
  height: 18px;
  font: normal normal normal 17px/18px Quattrocento Sans;
  letter-spacing: 0.17px;
  color: #31383e;
  opacity: 1;
  position: absolute;
  justify-content: space-between;
  white-space: normal;
  line-height: 50px;
}

.order-payment-note {
  font-size: 0.8em;
  opacity: 0.8;
  position: relative;
  top: -25px;
}

.divider {
  left: -300px;
  width: 195.5%;
  height: 0px;
  border: 2px solid #e5e5e5;
  opacity: 1;
  position: relative;
}

.divider-order {
  top: 200px;
}

.scan-product-orderpage {
  top: 350px;
  left: 440px;
  width: 200px;
  height: 25px;
  text-align: left;
  font: normal normal normal 22px/25px Quattrocento Sans;
  letter-spacing: 1.1px;
  color: #31383e;
  opacity: 1;
  position: relative;
}

.scan-stylecode-input {
  top: 410px;
  left: 440px;
  width: 505px;
  height: 77px;
  background: #ffffff 0% 0% no-repeat padding-box;
  border: 1px solid #b7b7b7;
  border-radius: 15px;
  opacity: 1;
  position: absolute;
  text-align: left;
  font: italic normal normal 17px/18px Quattrocento Sans;
  letter-spacing: 0.17px;
}

.scan-product-tickcheckbox {
  top: 500px;
  left: 460px;
  width: 250px;
  height: 25px;
  position: absolute;
  text-align: left;
  font: italic normal normal 16px/18px Quattrocento Sans;
  letter-spacing: 0.16px;
  color: #31383e;
  opacity: 1;
}

.order-items-header {
  top: 580px;
  left: 91px;
  width: 500px;
  height: 25px;
  text-align: left;
  font: normal normal normal 22px/25px Quattrocento Sans;
  letter-spacing: 1.1px;
  color: #31383e;
  opacity: 1;
  position: absolute;
}
.orderitems {
  margin-bottom: 5px;
  top: 450px;
  left: -187px;
  position: relative;
}

.returns-header {
  margin-top: 1em;
}

.item-layout {
  top: 50px;
  left: 252px;
  width: 140%;
  height: auto;
  border: 2px solid #f6f6f6;
  border-radius: 10px;
  opacity: 1;
  font: normal normal normal 17px/18px Quattrocento Sans;
  color: #31383e;
  text-align: center;
  padding: 10px;
  background-color: #ffffff;
  line-height: 25px;
  position: relative;
  z-index: 1;
  margin: 27px;
}

.item-image {
  width: 100%;
  padding: 2em;
}

.item-layout-returning {
  background-color: rgb(250, 209, 134);
}

.item-layout-returned {
  background-color: rgb(221, 221, 221);
}

.checkbox-selection {
  background-color: #e4f4e6;
}

.checkbox-selection .item-layout-returning {
  background-color: transparent;
}

.divider-items {
  top: 550px;
}

.exception-bar {
  position: relative;
  top: 525px;
  left: -250px;
  width: 190.5%;
  height: 103px;
  background-color: #f6f6f6;
  text-align: right;
  font: normal normal bold 17px/18px Quattrocento Sans;
  letter-spacing: 0.17px;
  color: #31383e;
  opacity: 1;
  padding: 37px;
}

.exception-message {
  font: normal normal normal 17px/18px Quattrocento Sans;
  text-align: center;
  position: relative;
  top: 9px;
}

.orderpage-button {
  border: 1px solid #31383e;
  top: 570px;
  left: 430px;
  width: 505px;
  height: 84px;
  margin: 20px;
  border-radius: 20px;
  background: #ffffff 0% 0% no-repeat padding-box;
  opacity: 1;
  position: relative;
  align-content: center;
  font: normal normal normal 17px/18px Quattrocento Sans;
  letter-spacing: 0.85px;
  color: #31383e;
  align-items: flex-start;
}

.popup-background {
  top: 0px;
  left: 0px;
  width: 100vw;
  height: 100vh;
  background: #31383e 0% 0% no-repeat padding-box;
  position: fixed;
  display: flex;
  justify-content: center;
  align-items: center;
  opacity: 0.95;
  z-index: 10;
}

.popup-container {
  top: 200px;
  left: 520px;
  width: 742px;
  height: 618px;
  background-color: white;
  box-shadow: 0px 3px 6px #00000029;
  border: 1px solid #707070;
  border-radius: 30px;
  display: flex;
  flex-direction: column;
  padding: 25px;
  position: fixed;
  opacity: 1;
  z-index: 11;
}

.popup-container-noitem {
  height: 508px;
}

.pop-close-header {
  position: relative;
  display: flex;
  justify-content: flex-end;
  text-align: right;
}
.popup-close-button {
  border: none white 1px;
  font-size: 20px;
}

.popup-close-button:hover,
.popup-close-button:focus {
  background-color: white;
  border: 1px solid white;
  color: #31383e;
  text-shadow: 1px 1px 2px #31383e;
}

.popup-title {
  top: 0px;
  left: 150px;
  text-align: center;
  margin-top: 10px;
  position: relative;
  width: 400px;
  height: 23px;
  font: normal normal normal 21px/23px Quattrocento Sans;
  letter-spacing: 0.63px;
  color: #31383e;
  opacity: 1;
}

.popup-body {
  justify-content: center;
  position: relative;
  top: 20px;
  left: 190px;
  width: 226px;
  height: 18px;
  font: normal normal normal 16px/18px Quattrocento Sans;
  letter-spacing: 0.16px;
  color: #31383e;
  opacity: 1;
}

.custom-exception-inputbox {
  width: 250px;
  height: 77px;
  background: #ffffff 0% 0% no-repeat padding-box;
  border: 1px solid #b7b7b7;
  border-radius: 15px;
  opacity: 1;
  position: relative;
  text-align: left;
  font: italic normal normal 17px/18px Quattrocento Sans;
  letter-spacing: 0.17px;
}

.homepage-input ::placeholder,
.scan-stylecode-input ::placeholder,
.custom-exception-inputbox::placeholder {
  color: #bcbcbc;
}

.popup-footer {
  top: 300px;
  left: 145px;
  width: 449px;
  height: 87px;
  border: 1px solid #31383e;
  border-radius: 30px;
  opacity: 1;
  position: relative;
  text-align: center;
  font: normal normal normal 21px/23px Quattrocento Sans;
  letter-spacing: 0.63px;
  color: #31383e;
  opacity: 1;
}

.popup-footer-noitem {
  top: 250px;
  position: relative;
  left: 135px;
}

.invalid-order-error {
  top: 250px;
  left: 600px;
  width: 600px;
  height: 100px;
  font: normal normal normal 30px/30px Quattrocento Sans;
  letter-spacing: 1.1px;
  opacity: 1;
  position: fixed;
  z-index: 15;
}
